import avatar from './img/perfil2.jpeg';
import logo from './img/logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <img src={avatar} className="App-avatar" alt="avatar" />
        <p className='Titulo'>Agende já sua consulta</p>
        <div>
          <button className='button-pers' onClick={() => {window.open('https://api.whatsapp.com/send?phone=5579996065256&text=Ol%C3%A1%20nutri%2C%20gostaria%20de%20marcar%20uma%20consulta%20com%20voc%C3%AA.', '_blank');}}>
            Consultório Suzana Alves
          </button>
          <button className='button-pers' onClick={() => {window.open('https://api.whatsapp.com/send?phone=5579996065256&text=Ol%C3%A1%20Nutri%2C%20gostaria%20de%20agendar%20uma%20consulta%20online.', '_blank');}}>
            Atendimento online
          </button>
          <button className='button-pers' onClick={() => {window.open('https://api.whatsapp.com/send?phone=5579996065256&text=Ol%C3%A1%20nutri!%20Gostaria%20de%20agendar%20uma%20consulta%20domiciliar.', '_blank');}}>
            Atendimento domiciliar
          </button>
          <button className='button-pers' onClick={() => {window.open('https://api.whatsapp.com/send?phone=5579996065256&text=Ol%C3%A1%20Nutri%2C%20gostaria%20de%20tirar%20uma%20d%C3%BAvida.', '_blank');}}>
            Dúvidas ?
          </button>
        </div>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default App;
